import { AnalyticsApi } from '@asicsdigital/oneasics-blocks';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { PersistGate } from 'redux-persist/integration/react';

import { CURRENT_ENVIRONMENT } from 'common/api/api';
import BaseRoutes from 'common/routes/BaseRoutes';
import theme from 'common/theme';
import { persistor, store } from 'redux/store';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// DATADOG INIT CODE COMMENTED OUT FOR NOW
// if (CURRENT_ENVIRONMENT.environmentName === 'Staging' || CURRENT_ENVIRONMENT.environmentName === 'Production') {
//   datadogRum.init({
//     applicationId: 'e97ef35f-6bda-4cf1-9591-93ed4d405f72',
//     clientToken: 'pub4b9928811e66c925f82f6536cf1c0cb5',
//     site: 'datadoghq.com',
//     service: 'oneasics-portal',
//     env: CURRENT_ENVIRONMENT.environmentName,
//     version: process.env.REACT_APP_VERSION,
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 20,
//     trackResources: true,
//     trackLongTasks: true,
//     trackUserInteractions: true,
//     defaultPrivacyLevel: 'mask-user-input',
//   });
//   datadogRum.startSessionReplayRecording();
// }

const App = () => {
  const { Track } = useTracking({
    source_id: `Global Console ${CURRENT_ENVIRONMENT.environmentName}`,
  }, {
      dispatch: (data) => {
        const { asicsId, sessionId } = store.getState().global;
        const analyticsBaseData = {
          asics_id: asicsId,
          session_id: sessionId,
          page: window.location.pathname,
        };
        AnalyticsApi.logAnalytics({
          ...data,
          ...analyticsBaseData,
          event_name: `globalconsole-${data.event_name}`,
        });
      },
  });

  return (
    <Track>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router basename={process.env.PUBLIC_URL}>
                <BaseRoutes />
              </Router>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
    </Track>
  );
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App />);
