import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#485CC2',
      main: '#2B439C',
    },
    secondary: {
      main: '#001E63',
    },
    background: {
      default: '#fff',
      image: 'linear-gradient(#485CC2,#001E63)',
    },
    error: {
      main: '#BD411D',
    },
    warning: {
      light: 'rgba(185, 65, 29, 0.15)',
      main: '#B9411D',
    },
  },
});

export default theme;
