import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { PERSIST, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { clientsApi, mfaApi, oauthApi, storesApi, usersApi } from 'common/api/api';

import adminChangelogReducer from './adminChangelogSlice';
import globalReducer from './globalSlice';
import integrationsReducer from './integrationsSlice';
import SetTransform from './reduxPersistTransforms';
import rolesReducer from './rolesSlice';
import storesReducer from './storesSlice';
import usersReducer from './usersSlice';

const combinedReducers = combineReducers({
  adminChangelogs: adminChangelogReducer,
  global: globalReducer,
  integrations: integrationsReducer,
  [mfaApi.reducerPath]: mfaApi.reducer,
  [oauthApi.reducerPath]: oauthApi.reducer,
  roles: rolesReducer,
  [storesApi.reducerPath]: storesApi.reducer,
  stores: storesReducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  users: usersReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  transforms: [SetTransform],
  whitelist: ['global'],
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['global/setModalOptions', PERSIST],
    },
  }).concat(usersApi.middleware)
    .concat(clientsApi.middleware)
    .concat(mfaApi.middleware)
    .concat(storesApi.middleware)
    .concat(oauthApi.middleware),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
