export default {
  superadmin: [
    { name: 'Users', route: 'users' },
    { name: 'Roles', route: 'roles' },
    { name: 'Integrations', route: 'integrations' },
    { name: 'Stores', route: 'stores' },
    { name: 'Admin Changelogs', route: 'admin-changelogs' },
    { name: 'Deleted Users', route: 'deleted-users' },
  ],
  manager: [
    { name: 'Users', route: 'users' },
    { name: 'Integrations', route: 'integrations' },
    { name: 'Deleted Users', route: 'deleted-users' },
  ],
  integrationadmin: [{ name: 'Integrations', route: 'integrations' }],
  customersupport: [
    { name: 'Users', route: 'users' },
    { name: 'Deleted Users', route: 'deleted-users' },
  ],
  dataprivacyofficer: [
    { name: 'Users', route: 'users' },
    { name: 'Deleted Users', route: 'deleted-users' },
  ],
};
