import { nanoid } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => ({
    ...inboundState,
    accessToken: '',
    asicsId: inboundState.userToken && Date.now() < inboundState.userTokenExpires ? inboundState.asicsId : '',
    adminRole: inboundState.userToken && Date.now() < inboundState.userTokenExpires ? inboundState.adminRole : '',
    extraHeaderChips: [],
    extraHeaderDetails: '',
    has2fa: undefined,
    loading: false,
    modalOptions: {
      open: false,
    },
    notificationOpen: false,
    notificationMessage: null,
    notificationType: null,
    qrCode: '',
    qrCodeKey: '',
    supportedCountries: [],
    userToken: Date.now() < inboundState.userTokenExpires ? inboundState.userToken : '',
  }),
  // transform state being rehydrated
  (outboundState) => ({
    ...outboundState,
    accessToken: '',
    asicsId: outboundState.userToken && Date.now() < outboundState.userTokenExpires ? outboundState.asicsId : '',
    adminRole: outboundState.userToken && Date.now() < outboundState.userTokenExpires ? outboundState.adminRole : '',
    extraHeaderChips: [],
    extraHeaderDetails: '',
    has2fa: undefined,
    loading: false,
    modalOptions: {
      open: false,
    },
    notificationOpen: false,
    notificationMessage: null,
    notificationType: null,
    postLoginPath: outboundState.userToken ? undefined : outboundState.postLoginPath,
    qrCode: '',
    qrCodeKey: '',
    sessionId: outboundState.sessionId ? outboundState.sessionId : nanoid(),
    supportedCountries: [],
    userToken: Date.now() < outboundState.userTokenExpires ? outboundState.userToken : '',
  }),
  // define which reducers this transform gets called for.
  { whitelist: ['global'] },
);

export default SetTransform;
