import { toTitleCase } from './Strings';

export const methodToVerbs = {
  GET: { past: 'get', present: 'get', future: 'getting' },
  POST: { past: 'created', present: 'create', future: 'creating' },
  PUT: { past: 'added', present: 'add', future: 'adding' },
  PATCH: { past: 'updated', present: 'update', future: 'updating' },
  DELETE: { past: 'deleted', present: 'delete', future: 'deleting' },
  easterEgg: { past: 'OneASICS\'d', present: 'OneASICS', future: 'OneASICSing' },
};

export default (
  adjective = '',
  analyticsEventNamePrefix = '',
  response,
  verbsOverride = null,
) => {
  // This logic will have to be updated once the migration is finished (will break on response.headers...)
  // As of right now we can keep it in because verbsOverride is never passed as null in the project.
  const verbs = verbsOverride
    ?? methodToVerbs[`${response.headers?.['access-control-allow-methods']}`]
    ?? methodToVerbs.easterEgg;

  const codes = {
    400: {
      message: `There was an error ${verbs?.future} the ${adjective}. Please check that all form fields are correct and try again.`,
      analyticsEventBase: {
        event_name: `${analyticsEventNamePrefix}-bad-request`,
        event_type: 'error',
      },
    },
    401: {
      message: 'Your session has expired, please log in again.',
      analyticsEventBase: {
        event_name: `${analyticsEventNamePrefix}-unauthorized`,
        event_type: 'error',
      },
    },
    403: {
      message: `You do not have permissions to ${verbs?.present} ${adjective}. Please contact the OneASICS team to request permission to complete this action.`,
      analyticsEventBase: {
        event_name: `${analyticsEventNamePrefix}-forbidden`,
        event_type: 'error',
      },
    },
    404: {
      message: `${adjective} not ${verbs?.past}.`,
      analyticsEventBase: {
        event_name: `${analyticsEventNamePrefix}-not-found`,
        event_type: 'error',
      },
    },
    409: {
      message: `${toTitleCase(adjective)} already exists.`,
      analyticsEventBase: {
        event_name: `${analyticsEventNamePrefix}-conflict`,
        event_type: 'error',
      },
    },
    422: {
      message: `There was an error ${verbs?.future} the ${adjective}. Please check that all form fields are correct and try again.`,
      analyticsEventBase: {
        event_name: `${analyticsEventNamePrefix}-unprocessable-entity`,
        event_type: 'error',
      },
    },
    underage_birthday: {
      message: `Underage birthday selected. You cannot ${verbs?.present} this account until you have parental consent and a parental email.`,
      analyticsEventBase: {
        event_name: `${analyticsEventNamePrefix}-underage-birthday`,
        event_type: 'error',
      },
    },
    500: {
      message: 'An error occurred. Please try again.',
      analyticsEventBase: {
        event_name: `${analyticsEventNamePrefix}-internal-server-error`,
        event_type: 'error',
      },
    },
    genericError: {
      message: 'An error occurred. Please try again.',
      analyticsEventBase: {
        event_name: `${analyticsEventNamePrefix}-error`,
        event_type: 'error',
      },
    },
    success: {
      message: `${toTitleCase(adjective)} ${verbs?.past}!`,
      analyticsEventBase: {
        event_name: `${analyticsEventNamePrefix}-success`,
        event_type: 'success',
      },
    },
  };

  if ((response.status && response.ok === undefined) || !(response.ok || response.ok === undefined)) {
    if (Array.isArray(response.data?.reasons) && codes?.[response.data?.reasons[0]]) {
      return codes?.[response.data?.reasons[0]];
    }
    return codes?.[response.status] ?? codes.genericError;
  }
  return codes.success;
};
