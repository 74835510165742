import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';

const PrivateRoute = ({ adminRole, component: Component, isAuthed, pathsAllowedForRole, ...rest }) => {
  const [, basepath] = useLocation()
    .pathname
    .split('/');
  const allowedRoutes = pathsAllowedForRole.map(path => path.route);

  return (
    <Route
      render={props => {
        if (isAuthed) {
          if (allowedRoutes.includes(basepath)) {
            return <Component {...props} />;
          }
          props.location.pathname = '/';
          return <Component {...props} />;
        }
        return <Redirect to="/login" />;
      }}
      {...rest}
    />
  );
};

export default PrivateRoute;

PrivateRoute.defaultProps = {
  adminRole: null,
  location: {},
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  isAuthed: PropTypes.bool.isRequired,
  pathsAllowedForRole: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  adminRole: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};
