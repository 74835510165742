import { lazy, Suspense, useState } from 'react';

import { ObjectHelper } from '@asicsdigital/oneasics-blocks';
import styled from '@emotion/styled';
import { Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

import AppHeader from 'Components/AppHeader';
import NavDrawer from 'Components/Drawer';
import Modal from 'Components/Modal';
import Notification from 'Components/Notification';
import { oauthApi, usersApi } from 'common/api/api';
import pathsAllowedForRole from 'common/routes/routesAllowedForRole';
import { closeNotification, revokeUserToken, setSupportedCountries } from 'redux/globalSlice';

const AdminChangelogs = lazy(() => import ('./AdminChangelogs'));
const Dashboard = lazy(() => import('./Dashboard'));
const DeletedUsers = lazy(() => import('./DeletedUsers'));
const Integration = lazy(() => import('./Integration'));
const Integrations = lazy(() => import('./Integrations'));
const Roles = lazy(() => import('./Roles'));
const Store = lazy(() => import('./Store'));
const Stores = lazy(() => import('./Stores'));
const User = lazy(() => import('./User'));
const Users = lazy(() => import('./Users'));

const Root = styled('div')({
  display: 'flex',
});

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
}));

const StyledContainer = styled(Container)(({ theme, header }) => ({
  paddingBottom: theme.spacing(header ? 4 : 8),
}));

const Layout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  sessionStorage.removeItem('codeVerifier');

  const {
    adminRole,
    extraHeaderChips,
    extraHeaderDetails,
    notificationOpen,
    notificationMessage,
    notificationType,
    modalOptions,
  } = useSelector((state) => state.global);
  const routesToDisplay = pathsAllowedForRole[adminRole];

  const [logout] = oauthApi.useLogoutMutation();
  const [getSupportedCountries, { status }] = usersApi.useLazyGetSupportedCountriesQuery();

  if (status === 'uninitialized') {
    getSupportedCountries()
      .unwrap()
      .then((response) => {
        dispatch(setSupportedCountries(ObjectHelper.toUnderscore(response)));
      })
      .catch(() => {
        logout().then(() => {
          dispatch(revokeUserToken());
          history.push('/login');
        });
      });
  }

  const handleNavDrawerToggle = () => {
    setNavDrawerOpen(!navDrawerOpen);
  };

  const handleNotificationClose = () => {
    dispatch(closeNotification());
  };

  return (
    <Root>
      <Suspense fallback={<div>Loading...</div>}>
        <NavDrawer
          navDrawerOpen={navDrawerOpen}
          primaryNavElements={routesToDisplay}
          onNavDrawerClose={handleNavDrawerToggle}
        />
      </Suspense>
      <Content>
        <StyledContainer header="true" maxWidth="lg">
          <Suspense fallback={<div>Loading...</div>}>
            <AppHeader
              toggleDrawerButtonVisible
              extraHeaderChips={extraHeaderChips}
              extraHeaderDetails={extraHeaderDetails}
              onToggleDrawer={handleNavDrawerToggle}
            />
          </Suspense>
        </StyledContainer>
        <StyledContainer maxWidth="lg">
          <Suspense fallback={<div>Loading...</div>}>
            <Route component={AdminChangelogs} path="/admin-changelogs" />
            <Route exact component={Dashboard} path="/" />
            <Route component={DeletedUsers} path="/deleted-users" />
            <Route exact component={Integrations} path="/integrations" />
            <Route component={Integration} path="/integrations/:clientId" />
            <Route exact component={Roles} path="/roles" />
            <Route exact component={Stores} path="/stores" />
            <Route component={Store} path="/stores/:storeId" />
            <Route exact component={Users} path="/users" />
            <Route component={User} path="/users/:asicsId" />
          </Suspense>
        </StyledContainer>
      </Content>
      <Modal {...modalOptions} />
      <Notification
        message={notificationMessage}
        open={notificationOpen}
        type={notificationType}
        onClose={handleNotificationClose}
      />
    </Root>
  );
};

export default Layout;
