import { createSlice } from '@reduxjs/toolkit';

export const adminChangelogSlice = createSlice({
  name: 'adminChangelogs',
  initialState: {
    searchQuery: '',
    changelogList: [],
    nextUrl: '',
    loading: false,
    currentRequestId: undefined,
  },
  reducers: {
    setAdminChangelogs: (state, action) => {
      state.changelogList = action.payload.admin_changelogs;
      state.changelogList.forEach(log => {
        log.changed_state = JSON.stringify(log.changed_state);
        log.changed_state = log.changed_state.replace(/["{}\\]/g, '');
        if (log.changed_state === 'null')
          log.changed_state = '';
      });
      state.nextUrl = action.payload.next_url;
    },
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
});

export const {
  setAdminChangelogs,
  updateSearchQuery,
} = adminChangelogSlice.actions;

export default adminChangelogSlice.reducer;
