import { memo } from 'react';

import styled from '@emotion/styled';
import { Divider, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { useTracking } from 'react-tracking';

import GCLogo from 'assets/GCLogo.svg';
import { oauthApi } from 'common/api/api';
import { revokeUserToken } from 'redux/globalSlice';

export const drawerWidth = '240px';

const StyledDrawer = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,

  '& .MuiPaper-root': {
    width: drawerWidth,
    backgroundColor: '#F7F8F8',
  },
});

const StyledList = styled(List)({
  width: '100%',
  maxWidth: drawerWidth,
  backgroundColor: '#F7F8F8',
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(5),

  '&.active': {
    paddingLeft: theme.spacing(4),
    borderLeft: `${theme.spacing(1)} solid ${theme.palette.primary.light}`,
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const NavDrawer = memo(({
  navDrawerOpen,
  onNavDrawerClose,
  primaryNavElements,
  secondaryNavElements,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [logout] = oauthApi.useLogoutMutation();

  const handleNavLinkClick = (routeName) => {
    trackEvent({
      event_name: `nav-${routeName}`,
      event_type: 'click',
    });

    if (routeName === 'logout') {
      logout().then(() => {
        dispatch(revokeUserToken());
        history.push('/login');
      });
    }

    if (navDrawerOpen) {
      onNavDrawerClose();
    }
  };

  return (
    <StyledDrawer
      anchor="left"
      open={navDrawerOpen}
      variant={isMobile ? 'temporary' : 'permanent'}
      onClose={onNavDrawerClose}
    >
      <StyledNavLink
        to="/"
        onClick={onNavDrawerClose}
      >
        <img alt="logo" src={GCLogo}/>
      </StyledNavLink>
      <Divider />
      <StyledList component="nav">
        {primaryNavElements.map((element) => (
          <StyledListItem
            button
            component={NavLink}
            key={element.route}
            to={`/${element.route}`}
            onClick={() => {handleNavLinkClick(element.route);}}
          >
            <ListItemText primary={`${element.name}`} />
          </StyledListItem>
        ))}
      </StyledList>
      <Divider />
      <StyledList component="nav">
        {secondaryNavElements.map((element) => (
          <StyledListItem
            button
            component={NavLink}
            key={element.route}
            to={`/${element.route}`}
            onClick={() => {handleNavLinkClick(element.route);}}
          >
            <ListItemText primary={`${element.name}`} />
          </StyledListItem>
        ))}
        <StyledListItem
          button
          key="logout"
          onClick={() => {handleNavLinkClick('logout');}}
        >
          <ListItemText primary="Log Out" />
        </StyledListItem>
      </StyledList>
    </StyledDrawer>
  );
});

export default NavDrawer;

NavDrawer.displayName = 'NavDrawer';

NavDrawer.defaultProps = {
  navDrawerOpen: true,
  onNavDrawerClose: () => {},
  secondaryNavElements: [],
};

NavDrawer.propTypes = {
  primaryNavElements: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }),
  ).isRequired,
  navDrawerOpen: PropTypes.bool,
  onNavDrawerClose: PropTypes.func,
  secondaryNavElements: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }),
  ),
};
