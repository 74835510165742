import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';

import AppLayout from 'App/Layout';
import AuthLayout from 'Auth/Layout';
import { setPostLoginPath } from 'redux/globalSlice';

import PrivateRoute from './PrivateRoute';
import pathsAllowedForRole from './routesAllowedForRole';

const BaseRoutes = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  const {
    adminRole,
    asicsId,
    postLoginPath,
    userToken,
    userTokenExpires,
  } = useSelector((state) => state.global);
  const isAuthed = Boolean(adminRole) && Boolean(asicsId) && Boolean(userToken) && Date.now() < userTokenExpires;
  const isLoginPath = location.pathname.includes('login');

  useEffect(() => {
    trackEvent({
      event_name: 'pageview',
      event_type: 'pageview',
    });
  }, [location.pathname, trackEvent]);

  useEffect(() => {
    if (!isAuthed && !isLoginPath) {
      dispatch(setPostLoginPath(location.pathname));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuthed) {
      if (isLoginPath) {
        history.replace(postLoginPath ?? '/');
      } else {
        history.replace(location.pathname);
      }
    }
  }, [userToken, history, location.pathname, postLoginPath, isLoginPath, isAuthed]);

  return (
    <Switch>
      <Route component={AuthLayout} path="/login"/>
      <PrivateRoute
        adminRole={adminRole}
        component={AppLayout}
        isAuthed={isAuthed}
        path="/"
        pathsAllowedForRole={pathsAllowedForRole[adminRole] || []}/>
      {/* <Route component={NotFound} /> */}
    </Switch>
  );
};

export default BaseRoutes;
