import { lazy, Suspense, useEffect } from 'react';

import styled from '@emotion/styled';
import { Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';

import Notification from 'Components/Notification';
import { closeNotification } from 'redux/globalSlice';

const Start = lazy(() => import('./Start'));
const MFAInfo = lazy(() => import('./MFAInfo'));
const MFASetup = lazy(() => import('./MFASetup'));
const MFAConfirm = lazy(() => import('./MFAConfirm'));

const Root = styled.div(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  background: theme.palette.background.image,
  alignItems: 'center',
}));

const Layout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    accessToken,
    adminRole,
    has2fa,
    qrCode,
    qrCodeKey,
    notificationOpen,
    notificationMessage,
    notificationType,
  } = useSelector((state) => state.global);

  useEffect(() => {
    if (accessToken && adminRole) {
      if (has2fa) {
        history.replace('/login/mfa/confirm');
      } else if (qrCode && qrCodeKey) {
        history.replace('/login/mfa/info');
      }
    } else {
      history.replace(`/login${location.search}`);
    }
  }, [accessToken, adminRole, has2fa, history, location.search, qrCode, qrCodeKey]);

  const handleNotificationClose = () => {
    dispatch(closeNotification());
  };

  return (
    <Root>
      <Grid container alignItems="center" component="main" justifyContent="center">
        <Grid item component={Paper} lg={5} md={6} xs={12}>
          <Suspense fallback={<div/>}>
            <Route exact component={Start} path="/login"/>
            <Route component={MFAInfo} path="/login/mfa/info"/>
            <Route component={MFASetup} path="/login/mfa/setup"/>
            <Route component={MFAConfirm} path="/login/mfa/confirm"/>
          </Suspense>
        </Grid>
      </Grid>
      <Notification
        message={notificationMessage}
        open={notificationOpen}
        type={notificationType}
        onClose={handleNotificationClose}
      />
    </Root>
  );
};

export default Layout;
