import { createSlice } from '@reduxjs/toolkit';

import { CLIENT_ID } from 'common/api/api';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    searchQuery: '',
    selectedUser: null,
  },
  reducers: {
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    createNewUserTemplate: (state) => {
      state.selectedUser = {
        newUser: true,
        migration_uuid: '',
        account_types: [],
        parent_email: '',
        email: '',
        first_name: '',
        last_name: '',
        full_name: '',
        birthday: '',
        country: { country_code: '  ', country_name: 'Unspecified' },
        password: '',
        gender: 'unspecified',
        role: 'delete_admin',
        is_underage: false,
        parental_consent: false,
        receive_newsletter: false,
        clutch_id: '',
        client_id: CLIENT_ID,
      };
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
});

export const {
  createNewUserTemplate,
  updateSearchQuery,
  setSelectedUser,
} = usersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default usersSlice.reducer;
