import { format, isValid, parseISO } from 'date-fns';

const uppercaseWords = ['asics', 'id']; // add new words here that should be all caps, but add them as all lowercase

export const toTitleCase = (str, separator = ' ') =>
  str
    .toLowerCase()
    .split(separator)
    .map((word) => (uppercaseWords.includes(word) ? word.toUpperCase() : word.charAt(0).toUpperCase() + word.slice(1)))
    .join(' ');

export const valueToType = (value, dateTime) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  try {
    if (!isNaN(value)) { // eslint-disable-line no-restricted-globals
      return value;
    }
    if (isValid(parseISO(value))) {
      return dateTime ? format(new Date(value), 'MM/dd/y, hh:mm:ss a') : format(new Date(value), 'MM/dd/y');
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  if (Array.isArray(value)) {
    return value.map((item) => item).join(', ');
  }

  if (typeof value === 'string') {
    return value.replace(/,\s*/gi, ', ');
  }

  return value;
};

export const objectToBodyString = (params) => {
  if (Boolean(params) && typeof params === 'object') {
    return Object.keys(params)
      .map((key) => (params[key]?.length || typeof params[key] === 'boolean' ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}` : ''))
      .filter((item) => item !== '')
      .join('&');
  }

  return '';
};

export const objectToQueryString = (params) => {
  const bodyString = objectToBodyString(params);

  if (bodyString.length) {
    return `?${bodyString}`;
  }

  return '';
};

// Take a string formatted as MM/dd/yyyy and return it as yyyy-MM-mm
export const formatDateStringToISO = (formattedDate) => {
  if (!formattedDate) {
    return '';
  }
  const [month, day, year] = formattedDate.split('/');
  return `${year}-${month}-${day}`;
};

// Take a string formatted as yyyy-MM-mm and return it as MM/dd/yyyy
export const formatISOToDateString = (ISODate) => {
  if (!ISODate) {
    return '';
  }
  const [year, month, day] = ISODate.split('-');
  return `${month}/${day}/${year}`;
};
