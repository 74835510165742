import { Alert as MuiAlert, Slide, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const Notification = ({
  autoHideDuration,
  horizontal,
  message,
  onClose,
  open,
  slideDirection,
  type,
  vertical,
}) => {
  const SlideTransition = (props) => (
    <Slide {...props} mountOnEnter unmountOnExit direction={slideDirection} in={open} />
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={autoHideDuration}
        open={open}
        TransitionComponent={SlideTransition}
        onClose={onClose}
      >
        <div>
          <Alert severity={type}>{message}</Alert>
        </div>
      </Snackbar>
    </div>
  );
};

export default Notification;

Notification.defaultProps = {
  autoHideDuration: 5000,
  horizontal: 'center',
  message: 'Add a notification message prop',
  slideDirection: 'down',
  type: 'info',
  vertical: 'top',
};

Notification.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  autoHideDuration: PropTypes.number,
  horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  message: PropTypes.string,
  slideDirection: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  vertical: PropTypes.oneOf(['top', 'bottom']),
};
