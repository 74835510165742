import { createSlice } from '@reduxjs/toolkit';

export const storesSlice = createSlice({
  name: 'stores',
  initialState: {
    searchQuery: '',
    storeList: [],
    selectedStore: null,
    loading: false,
    currentRequestedId: undefined,
  },
  reducers: {
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    createNewStoresTemplate: (state) => {
      state.selectedStore = {
        newStore: true,
        id: '',
        client_ids: [],
        name: '',
        connector_id: '',
        region_id: 'unspecified',
        country_id: '  ',
        store_type: 'unspecified',
        is_active: true,
      };
    },
    duplicateStore: (state, action) => {
      state.selectedStore = {
        ...action.payload,
        id: '',
        name: '',
        duplicatedStore: action.payload.id,
        newStore: true,
      };
    },
    setSelectedStore: (state, action) => {
      state.selectedStore = action.payload;
    },
  },
});

export const {
  createNewStoresTemplate,
  duplicateStore,
  setSelectedStore,
  updateSearchQuery,
} = storesSlice.actions;

export default storesSlice.reducer;
