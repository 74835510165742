import { createSlice } from '@reduxjs/toolkit';

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    searchQuery: '',
    roleTitles: [
      'Super Admin',
      'Manager',
      'Integration Admin',
      'Customer Support',
      'Data Privacy Officer',
    ],
  },
  reducers: {
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
});

export const {
  updateSearchQuery,
 } = rolesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default rolesSlice.reducer;
