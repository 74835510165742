import { createSlice, nanoid } from '@reduxjs/toolkit';

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    searchQuery: '',
    selectedIntegration: null,
  },
  reducers: {
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    createNewIntegrationTemplate: (state) => {
      state.selectedIntegration = {
        newIntegration: true,
        client_id: '',
        client_name: '',
        first_name_visible: true,
        first_name_required: false,
        last_name_visible: true,
        last_name_required: false,
        full_name_visible: false,
        full_name_required: false,
        gender_visible: false,
        gender_required: false,
        send_welcome_email: false,
        send_sfmc_marketing: false,
        secret: nanoid(32),
        business_owner_emails: [],
        redirect_uris: [],
        region_id: 'Global',
        rk_comp: false,
        rk_comp_countries: [],
        auth_level: [],
        category: 'other',
        scope: false,
      };
    },
    duplicateIntegration: (state, action) => {
      state.selectedIntegration = {
        ...action.payload,
        client_id: '',
        client_name: '',
        created_at: null,
        duplicatedIntegration: action.payload.client_id,
        newIntegration: true,
        secret: nanoid(32),
        updated_at: null,
      };
    },
    setSelectedIntegration: (state, action) => {
      state.selectedIntegration = { ...action.payload, scope: action.payload?.scope.length < 2 };
    },
  },
});

export const {
  createNewIntegrationTemplate,
  duplicateIntegration,
  setSelectedIntegration,
  updateSearchQuery,
} = integrationsSlice.actions;

export default integrationsSlice.reducer;
