import { memo } from 'react';

import styled from '@emotion/styled';
import { ChevronLeft as ChevronLeftIcon, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Button, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { toTitleCase } from 'common/utilities/Strings';

const HtmlTooltip = styled(Tooltip)({
  textAlign: 'center',
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  paddingTop: theme.spacing(4),
  borderBottom: '1px solid #ccc',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  paddingLeft: theme.spacing(1),
  paddingBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-start',
  color: theme.palette.primary.main,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const AppHeader = memo(
  ({ extraHeaderDetails, extraHeaderChips, history, location, onToggleDrawer, toggleDrawerButtonVisible }) => {
    const [page] = location.pathname
      .split('/')
      .filter((str) => str !== '')
      .map((str) => toTitleCase(str.replace(/-/g, ' ')));

    return (
      <StyledAppBar color="transparent" elevation={0} position="static">
        <StyledTypography variant="h5">
          {toggleDrawerButtonVisible && (
            <StyledIconButton
              aria-label="open drawer"
              color="inherit"
              edge="start"
              size="large"
              onClick={onToggleDrawer}>
              <MenuIcon />
            </StyledIconButton>
          )}
          {page ?? 'Home'}
          {extraHeaderDetails ? `: ${extraHeaderDetails}` : ''}
          {extraHeaderChips &&
            extraHeaderChips.map(
              (chip) =>
                (chip.text || chip.icon) && (
                  <HtmlTooltip
                    arrow
                    key={chip.text}
                    title={chip.tooltip ? chip.tooltip : ''}
                  >
                    <StyledChip
                      color={chip.color || 'primary'}
                      icon={chip.icon}
                      label={chip.text}
                      style={ chip.customColor && { backgroundColor: chip.customColor } }
                    />
                  </HtmlTooltip>
                ),
            )}
        </StyledTypography>
        {page && (
          <StyledButton startIcon={<ChevronLeftIcon />} onClick={() => history.goBack()}>
            Go Back
          </StyledButton>
        )}
      </StyledAppBar>
    );
  },
);

export default withRouter(AppHeader);

AppHeader.displayName = 'AppHeader';

AppHeader.defaultProps = {
  extraHeaderChips: undefined,
  extraHeaderDetails: undefined,
  onToggleDrawer: () => {},
  toggleDrawerButtonVisible: false,
};

AppHeader.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  extraHeaderChips: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      icon: PropTypes.node,
      text: PropTypes.string,
    }),
  ),
  extraHeaderDetails: PropTypes.string,
  onToggleDrawer: PropTypes.func,
  toggleDrawerButtonVisible: PropTypes.bool,
};
