import {  createSlice, nanoid } from '@reduxjs/toolkit';

import responseHandler from '../common/utilities/ResponseHandler';

// Sessions expire in 7 hours 45 minutes.
const SESSION_LENGTH_MILLI = 7.75 * 60 * 60 * 1000;

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    asicsId: '',
    sessionId: nanoid(),
    adminRole: '',
    postLoginPath: undefined,
    accessToken: '',
    has2fa: undefined,
    qrCode: '',
    qrCodeKey: '',
    extraHeaderDetails: '',
    extraHeaderChips: [],
    loading: false,
    supportedCountries: [],
    currentRequestId: undefined,
    modalOptions: {
      open: false,
    },
    notificationOpen: false,
    notificationMessage: null,
    notificationType: null,
    userToken: '', // 2FA token
    userTokenExpires: Date.now(), // 2FA token
  },
  reducers: {
    confirm2fa: (state, action) => {
      state.userToken = action.payload.userToken;
      state.userTokenExpires = Date.now() + SESSION_LENGTH_MILLI;
    },
    reset2fa: (state) => {
      state.notificationMessage = '2FA successfully reset';
      state.notificationType = 'success';
      state.notificationOpen = true;
    },
    set2faStatus: (state, action) => {
      state.has2fa = action.payload.isSetup;
      state.adminRole = action.payload.adminRole;
      state.asicsId = action.payload.asicsId;

      if (!action.payload.isSetup) {
        state.qrCode = action.payload.qrCodeUrl;
        state.qrCodeKey = action.payload.secretKey;
      }
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload.data.access_token;
    },
    setExtraHeaderDetails: (state, action) => {
      state.extraHeaderDetails = action.payload;
    },
    setExtraHeaderChips: (state, action) => {
      state.extraHeaderChips = action.payload;
    },
    setModalOptions: (state, action) => {
      state.modalOptions = { ...action.payload };
    },
    setPostLoginPath: (state, action) => {
      state.postLoginPath = action.payload;
    },
    setSupportedCountries: (state, action) => {
      state.supportedCountries = action.payload.sort();
    },
    showNotification: (state, action) => {
      state.notificationMessage = action.payload.message;
      state.notificationType = action.payload.type;
      state.notificationOpen = true;
    },
    closeNotification: (state) => {
      state.notificationOpen = false;
      state.notificationMessage = null;
      state.notificationType = null;
    },
    revokeUserToken: (state) => {
      state.userToken = '';
    },
  },
});

export const {
  closeNotification,
  confirm2fa,
  revokeUserToken,
  reset2fa,
  setAccessToken,
  set2faStatus,
  setExtraHeaderDetails,
  setExtraHeaderChips,
  setModalOptions,
  setPostLoginPath,
  setSupportedCountries,
  showNotification,
} = globalSlice.actions;

export default globalSlice.reducer;

export const handleResponse = (adjective, analyticsEventNamePrefix, dispatch, response, verbsOverride) => {
  const { message, analyticsEventBase } = responseHandler(adjective, analyticsEventNamePrefix, response, verbsOverride);
  if (dispatch) {
    dispatch(showNotification({ message, type: !response.status || response.ok ? 'success' : 'error' }));
  }
  return { ...response, analyticsEventBase };
};
