import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

const Modal = ({ closeButtonText, description, onClose, open, saveAndCloseButtonText, title }) => (
  <Dialog
    aria-describedby="alert-dialog-description"
    aria-labelledby="alert-dialog-title"
    open={open}
    onClose={() => onClose(false)}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={() => onClose(false)}>
        {closeButtonText}
      </Button>
      {saveAndCloseButtonText && (
        <Button color="primary" onClick={() => onClose(true)}>
          {saveAndCloseButtonText}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

export default Modal;

Modal.defaultProps = {
  closeButtonText: '',
  description: '',
  onClose: undefined,
  saveAndCloseButtonText: '',
  title: '',
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeButtonText: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
  saveAndCloseButtonText: PropTypes.string,
  title: PropTypes.string,
};
